<template>
    <div class="">
        <div class="">
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                            <div class="card">
                                <div class="card-body table-responsive table-head">
                                    <div class="row bor-bot">
                                        <div class="col-md-8 pl-0">
                                            <h1 class="m-0 text-dark pt-2 pb-2">Admin User List</h1>
                                        </div>
                                         <div class="col-md-4">
                                        <div class="text-right pb-3">
                                        <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                        </div>
                                    </div>
                                    </div>
                                <div class="row">
                                   <div class="col-lg-8 mr-0 pr-0">
                                       <div class="mt-3">
                                            <b-form inline>
                                                <label class="mr-sm-2 font-weight-normal">Show</label>
                                                <b-form-select
                                                    id="inline-form-custom-select-pref"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    v-model="perPage"
                                                    :options="viewEntryOptions"
                                                    size="sm"
                                                ></b-form-select>
                                                entries
                                            </b-form>
                                        </div>
                                    </div>

                                        <div class="col-md-4 text-right">
                                        <div class="card-text d-flex align-items-center float-right">
                                             <div class="mt-3 ml-3">
                                            <b-input-group size="sm">
                                                <b-form-input
                                                    id="filter-input"
                                                    v-model="filter"
                                                    type="search"
                                                    placeholder="Search"
                                                ></b-form-input>
                                            </b-input-group>
                                             </div>
                                        
                                            <div class="mt-3 ml-3">
                                                <b-button class="btn-primary btn  btn-darkblue" @click="addRow($event.target)">Add
                                                    User
                                                </b-button>
                                            </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                                        <div class="overflow-auto">
                                            <b-table
                                                id="my-table"
                                                :per-page="perPage"
                                                :current-page="currentPage"
                                                :items="items"
                                                :fields="primarycontactfields"
                                                :busy="isBusy"
                                                class="mt-3"
                                                :filter="filter"
                                                :filter-included-fields="filterOn"
                                                @filtered="onFiltered"
                                                hover
                                                show-empty
                                            >
                                                <template
                                                    :fields="items"
                                                >
                                                    <div class="text-left py-0 align-middle">
                                                        <div class="btn-group btn-group-sm">
                                                            <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                                            <button class="btn btn-primary ml-1"><i
                                                                class="fas fa-pencil-alt"></i></button>
                                                            <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template #empty="scope">
                                                    <h4>{{ scope.emptyText }}</h4>
                                                </template>
                                                <template #cell(actions)="row">
                                                    <div class="text-left py-0 align-middle">
                                                        <div class="btn-group btn-group-sm">
                                                            <button class="btn btn-primary ml-1" @click="editRow(row.item, $event.target)"><i class="fas fa-pencil-alt"></i></button>
                                                            <button class="btn btn-danger ml-1"  @click="deleteRow(row.item.id)"><i class="fas fa-trash"></i></button>
                                                        </div>
                                                    </div>
                                                </template>
                                                <!--<template #table-busy>
                                                    <div class="text-center text-danger my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>Loading...</strong>
                                                    </div>
                                                </template>-->
                                            </b-table>
                                            <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                aria-controls="my-table"
                                            ></b-pagination>
                                        </div>
                                    </div>     
                                </div>   
                                    

                                    <b-modal
                                        id="edit-modal"
                                        :title="editModal.title"
                                        size="lg"
                                        ref="modal"
                                        @ok="handleOk"
                                        @hide="resetEditModal"
                                    >
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <b-form-input @keydown="errors.clear('first_name')"
                                                                  v-model="editModal.content.first_name"
                                                                  :class="errors.has('first_name') ? 'is-invalid' : ''"
                                                                  id="feedback-firstname"></b-form-input>
                                                    <b-form-invalid-feedback :state="!errors.has('first_name')">
                                                        {{ errors.get('first_name') }}
                                                    </b-form-invalid-feedback>
                                                </div>
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <b-form-input @keydown="errors.clear('last_name')"
                                                                  v-model="editModal.content.last_name"
                                                                  :class="errors.has('last_name') ? 'is-invalid' : ''"
                                                                  id="feedback-lastname"></b-form-input>
                                                    <b-form-invalid-feedback :state="!errors.has('last_name')">
                                                        {{ errors.get('last_name') }}
                                                    </b-form-invalid-feedback>
                                                </div>
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <b-form-input @keydown="errors.clear('email')"
                                                                  v-model="editModal.content.email"
                                                                  :type="emailType"
                                                                  :class="errors.has('email') ? 'is-invalid' : ''"
                                                                  id="feedback-email"></b-form-input>
                                                    <b-form-invalid-feedback :state="!errors.has('email')">
                                                        {{ errors.get('email') }}
                                                    </b-form-invalid-feedback>
                                                </div>
                                                <div class="form-group">
                                                    <label>Role</label>
                                                    <b-form-select v-model="editModal.content.role_id"
                                                                   :options="options"></b-form-select>
                                                    <b-form-invalid-feedback :state="!errors.has('role_id')">
                                                        {{ errors.get('role_id') }}
                                                    </b-form-invalid-feedback>

                                                </div>
                                                <div id="selector">
                                                    <div class="form-check mb-2">
                                                        <input type="checkbox" class="form-check-input"
                                                               v-model="checked" id="exampleCheck1">
                                                        <label class="form-check-label" for="exampleCheck1">Set
                                                            Password</label>
                                                    </div>
                                                    <!-- <div class="checkbox">
                                                        <label><input type="checkbox" v-model="checked"></label>
                                                    </div> -->
                                                    <div id="app-container" v-if="checked">
                                                        <div class="form-group">
                                                            <label for="feedback-password">Password:</label>
                                                            <b-form-input  :type="passwordType" v-model="editModal.content.password" :class="errors.has('password') ? 'is-invalid' : ''"
                                                                           id="feedback-password"></b-form-input>
                                                            <b-form-invalid-feedback :state="!errors.has('password')">
                                                                {{ errors.get('password') }}
                                                            </b-form-invalid-feedback>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="name">Confirm Password:</label>
                                                            <b-form-input  :type="passwordType" v-model="editModal.content.password_confirm" :class="errors.has('password_confirm') ? 'is-invalid' : ''"
                                                                           id="feedback-password_confirm"></b-form-input>
                                                            <b-form-invalid-feedback :state="!errors.has('password_confirm')">
                                                                {{ errors.get('password_confirm') }}
                                                            </b-form-invalid-feedback>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </b-modal>
                                </div>
                            </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
        <Footer></Footer>
    </div>
</template>

<script>
import axios from "axios";
import Errors from "../../Errors";
import CustomAlert from '../CustomAlert';

let cachedData = {};
export default {
    components: {CustomAlert},
    data() {
        return {
            alertMessage: "",
            displayAlert: "",
            passwordType: 'password',
            emailType: 'email',
            loaded: false,
            perPage: 10,
            currentPage: 1,
            errors: new Errors(),
            checked: false,
            isBusy: false,
            options: [],
            primarycontactfields: [
                {
                    key: 'name',
                    sortable: true
                },

                {
                    key: 'email',
                    sortable: true,
                },
                {
                    key: 'role',
                    sortable: true,
                },
                {
                    key: 'actions',
                    sortable: false,
                },
            ],
            items: [],
            viewEntryOptions: [
                {value: 10, text: '10'},
                {value: 20, text: '20'},
                {value: 50, text: '50'},
                {value: 100, text: '100'},
            ],
            filter: null,
            filterOn: [],
            editModal: {
                editing: true,
                id: 'edit-modal',
                title: '',
                content: ''
            },
        }
    },
    methods: {
        resetAlert(){
            this.displayAlert = false;
        },
        addRow(button) {
            this.editModal.editing = false;
            this.editModal.title = 'Add New User';
            this.editModal.content = {first_name: '', last_name: '', email: '', role: null};
            this.$root.$emit('bv::show::modal', 'edit-modal', button);
            this.checked = false;
        },
        getRoles() {
            axios.get(process.env.VUE_APP_URL + 'api/roles/getRoleSelect')
                .then((response) => {
                    this.options = response.data.roles;
                })
                .catch(error => this.errors = error.response.data);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        editRow(item, button) {
            this.editModal.editing = true,
                this.editModal.title = 'Update ' + item.name;
            this.editModal.content = item;
            this.$root.$emit('bv::show::modal', 'edit-modal', button);
            this.checked = false;
        },
      deleteRow(id) {
        if (window.confirm("Are you sure to delete this item?")) {
          this.displayAlert = false;
          axios.delete(process.env.VUE_APP_URL + 'api/users/delete/' + id)
              .then(response => {
                  this.alertMessage = "User is deleted";
                  this.displayAlert = true;
                  this.resetEditModal();
              })
              .catch(error => {
                error => this.errors = error.response.data
              });
        }
        return false;
      },


        //Modals
        resetInfoModal() {
            this.infoModal.title = '';
            this.infoModal.content = '';
        },
        resetEditModal() {
            this.errors.clear();
            this.loaded = false;
            axios.get(process.env.VUE_APP_URL + 'api/user/getUsers')
                .then((response) => {
                    cachedData = response.data.users;
                    this.items = cachedData;
                    this.loaded = true;
                })
                .catch(error => this.errors = error.response.data);
            this.loaded = true;
            this.loaded = false;
        },

        // Submitting
        onFail(errors) {
            this.errors.record(errors);
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            let url = process.env.VUE_APP_URL + 'api/users';
            let requestType = 'post';

            if (this.editModal.editing) {
                url = process.env.VUE_APP_URL + 'api/users/update/' + this.editModal.content.id;
                requestType = 'patch';
            }
            this.displayAlert = false;
            this.handleSubmit(requestType, url)
                .then(() => {
                    this.$nextTick(() => {
                        var message = this.editModal.editing ? "saved" : "created";
                        this.alertMessage = "User has been " + message;
                        this.displayAlert = true;
                        this.$bvModal.hide('edit-modal');
                    })
                }).catch(() => false);
        },

        handleSubmit(requestType, url) {
            return new Promise((resolve, reject) => {
                if(this.checked == false) {
                    delete this.editModal.content.password;
                    delete this.editModal.content.password_confirm;
                }
                axios[requestType](url, this.editModal.content)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        this.onFail(error.response.data.errors);
                        reject(error.response.data);
                    });
            });
        },
    },
    created() {
        if (Object.keys(cachedData).length === 0) {
            axios.get(process.env.VUE_APP_URL + 'api/user/getUsers')
                .then((response) => {
                    cachedData = response.data.users;
                    this.items = cachedData;
                    this.loaded = true;
                })
                .catch(error => this.errors = error.response.data);
            return false;
        }
        this.items = cachedData;
        this.loaded = true;
    },
    mounted() {
        this.getRoles();
    },
    computed: {
        rows() {
            return this.items.length
        },
    },

}

</script>
